<template>
  <div class="container">
    <transition name="fade" mode="out-in">
      <!-- Form -->
      <main
        v-if="!isVerifyingToken && isTokenVerified && !registerSuccess"
        key="show_form"
      >
        <div class="py-5 text-center">
          <img
            class="d-block mx-auto mb-4"
            src="@/assets/logo.png"
            alt="Logo XTC Indonesia"
            width="128"
            height="128"
          />
          <h1>Pendaftaran Anggota XTC</h1>
          <p class="lead">
            Silakan melengkapi formulir pendaftaran dibawah untuk bergabung.
          </p>
        </div>

        <div class="row g-5">
          <div class="col-md-12">
            <form class="needs-validation" @submit.prevent="submitForm">
              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Informasi Umum</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Informasi umum tentang anggota seperti nama, no hp, status,
                    email, dll.
                  </h6>
                  <hr />
                  <div class="row mt-3 px-2">
                    <div class="col-md-6 mb-3">
                      <label for="nama_lengkap" class="form-label"
                        >Nama Lengkap</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="anggota.nama_lengkap"
                        :class="{
                          'is-invalid': $v.anggota.nama_lengkap.$error,
                        }"
                      />
                      <div
                        v-if="$v.anggota.nama_lengkap.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.anggota.nama_lengkap.required"
                          >Nama lengkap harus di isi.</span
                        >
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Nama Panggilan</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="anggota.nama_panggilan"
                      />
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Jenis Kelamin</label>
                      <select
                        class="form-select"
                        v-model="anggota.jenis_kelamin"
                      >
                        <option value="">Pilih...</option>
                        <option
                          v-for="option in jenis_kelamin"
                          :value="option"
                          :key="option"
                        >
                          {{ option }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Tanggal Lahir</label>
                      <v-date-picker
                        v-model="anggota.tanggal_lahir"
                        :locale="locale"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            class="form-control"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </v-date-picker>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Email</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="anggota.email"
                        :class="{ 'is-invalid': $v.anggota.email.$error }"
                        disabled
                      />
                      <div
                        v-if="$v.anggota.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.anggota.email.required"
                          >Email harus di isi.</span
                        >
                        <span v-if="!$v.anggota.email.email"
                          >Format email harus benar.</span
                        >
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">No. HP</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="anggota.no_hp"
                      />
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Agama</label>
                      <select class="form-select" v-model="anggota.agama">
                        <option value="">Pilih...</option>
                        <option
                          v-for="option in agama"
                          :value="option"
                          :key="option"
                        >
                          {{ option }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Status</label>
                      <select class="form-select" v-model="anggota.status">
                        <option value="">Pilih...</option>
                        <option
                          v-for="option in status"
                          :value="option"
                          :key="option"
                        >
                          {{ option }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Jenis Identitas</label>
                      <select
                        class="form-select"
                        v-model="anggota.jenis_identitas"
                        :class="{
                          'is-invalid': $v.anggota.jenis_identitas.$error,
                        }"
                      >
                        <option value="">Pilih...</option>
                        <option
                          v-for="option in jenis_identitas"
                          :value="option"
                          :key="option"
                        >
                          {{ option }}
                        </option>
                      </select>
                      <div
                        v-if="$v.anggota.jenis_identitas.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.anggota.jenis_identitas.required"
                          >Jenis Identitas harus di isi.</span
                        >
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">No. Identitas</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="anggota.no_identitas"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Alamat</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Informasi alamat sesuai dengan domisili KTP
                  </h6>
                  <hr />
                  <div class="row mt-3 px-2">
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Provinsi</label>
                      <v-select
                        v-model="anggota.propinsi_id"
                        :options="provinsi"
                        :reduce="(provinsi) => provinsi.id"
                        label="nama"
                        @input="onProvinceChange"
                        :class="{ 'is-invalid': $v.anggota.propinsi_id.$error }"
                      >
                        <template #search="{ attributes, events }">
                          <input
                            class="vs__search"
                            :class="{
                              'is-invalid': $v.anggota.propinsi_id.$error,
                            }"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Kota/Kab.</label>
                      <v-select
                        v-model="anggota.kota_id"
                        :options="kota"
                        :reduce="(kota) => kota.id"
                        label="nama"
                        @input="onKotaChange"
                        :class="{ 'is-invalid': $v.anggota.kota_id.$error }"
                      >
                        <template #search="{ attributes, events }">
                          <input
                            class="vs__search"
                            :class="{
                              'is-invalid': $v.anggota.kota_id.$error,
                            }"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Kecamatan</label>
                      <v-select
                        v-model="anggota.kecamatan_id"
                        :options="kecamatan"
                        :reduce="(kecamatan) => kecamatan.id"
                        label="nama"
                        @input="onKecamatanChange"
                        :class="{
                          'is-invalid': $v.anggota.kecamatan_id.$error,
                        }"
                      >
                        <template #search="{ attributes, events }">
                          <input
                            class="vs__search"
                            :class="{
                              'is-invalid': $v.anggota.kecamatan_id.$error,
                            }"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Kelurahan</label>
                      <v-select
                        v-model="anggota.kelurahan_id"
                        :options="kelurahan"
                        :reduce="(kelurahan) => kelurahan.id"
                        label="nama"
                      >
                      </v-select>
                    </div>
                    <div class="col-md-12 mb-3">
                      <label class="form-label">Alamat</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="anggota.alamat"
                        :class="{ 'is-invalid': $v.anggota.alamat.$error }"
                      />
                      <div
                        v-if="$v.anggota.alamat.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.anggota.alamat.required"
                          >Alamat harus di isi.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Hobi & Pekerjaan</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Informasi tentang Hobi & Pekerjaan
                  </h6>
                  <hr />
                  <div class="row mt-3 px-2">
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Kategori Hobi</label>
                      <v-select
                        v-model="anggota.kategori_hobi_id"
                        :options="kategori_hobi"
                        :reduce="(kategori_hobi) => kategori_hobi.id"
                        label="nama"
                        @input="onKategoriHobiChange"
                      >
                      </v-select>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Hobi</label>
                      <v-select
                        v-model="anggota.hobi_id"
                        :options="hobi"
                        :reduce="(hobi) => hobi.id"
                        label="nama"
                      >
                      </v-select>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Kategori Pekerjaan</label>
                      <v-select
                        v-model="anggota.kategori_pekerjaan_id"
                        :options="kategori_pekerjaan"
                        :reduce="(kategori_pekerjaan) => kategori_pekerjaan.id"
                        label="nama"
                        @input="onKategoriPekerjaanChange"
                      >
                      </v-select>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Pekerjaan</label>
                      <v-select
                        v-model="anggota.pekerjaan_id"
                        :options="pekerjaan"
                        :reduce="(pekerjaan) => pekerjaan.id"
                        label="nama"
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Informasi KTA</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Informasi tentang KTA seperti status KTA, Foto, Jenis KTA,
                    dll.
                  </h6>
                  <hr />
                  <div class="row mt-3 px-2">
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Biaya Pendaftaran</label>
                      <select
                        class="form-select"
                        v-model="anggota.jenis_atribut"
                        :class="{
                          'is-invalid': $v.anggota.jenis_atribut.$error,
                        }"
                        @change="onJenisAtributChange"
                      >
                        <option value="">Pilih...</option>
                        <option
                          v-for="option in jenis_atribut"
                          :value="option"
                          :key="option"
                        >
                          {{ option }}
                        </option>
                      </select>
                      <div
                        v-if="$v.anggota.jenis_atribut.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.anggota.jenis_atribut.required"
                          >Biaya Pendaftaran harus di isi.</span
                        >
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Ukuran Atribut</label>
                      <select
                        class="form-select"
                        v-model="anggota.ukuran_atribut"
                        :class="{
                          'is-invalid': $v.anggota.ukuran_atribut.$error,
                        }"
                        :disabled="hanyaKta"
                      >
                        <option value="">Pilih...</option>
                        <option
                          v-for="option in ukuran_atribut"
                          :value="option"
                          :key="option"
                        >
                          {{ option }}
                        </option>
                      </select>
                      <div
                        v-if="$v.anggota.ukuran_atribut.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.anggota.ukuran_atribut.required"
                          >Ukuran Atribut harus di isi.</span
                        >
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label for="foto_identitas" class="form-label"
                        >Foto Identitas</label
                      >
                      <input
                        class="form-control"
                        type="file"
                        @change="changeFotoIdentitas"
                        accept="image/png, image/jpeg"
                        :class="{
                          'is-invalid': $v.anggota.foto_identitas.$error,
                        }"
                      />
                      <div
                        v-if="$v.anggota.foto_identitas.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.anggota.foto_identitas.required"
                          >Foto Identitas harus di isi.</span
                        >
                      </div>
                    </div>

                    <div class="col-md-4">
                      <label for="foto_memegang_identitas" class="form-label"
                        >Foto Memegang Identitas</label
                      >
                      <input
                        class="form-control"
                        type="file"
                        @change="changeFotoMemegangIdentitas"
                        accept="image/png, image/jpeg"
                        :class="{
                          'is-invalid':
                            $v.anggota.foto_memegang_identitas.$error,
                        }"
                      />

                      <div
                        v-if="$v.anggota.foto_memegang_identitas.$error"
                        class="invalid-feedback"
                      >
                        <span
                          v-if="!$v.anggota.foto_memegang_identitas.required"
                          >Foto saat memegang identitas harus di isi.</span
                        >
                      </div>
                    </div>

                    <div class="col-md-4">
                      <label for="foto_diri" class="form-label"
                        >Foto Diri</label
                      >
                      <input
                        class="form-control"
                        type="file"
                        @change="changeFotoDiri"
                        accept="image/png, image/jpeg"
                        :class="{ 'is-invalid': $v.anggota.foto_diri.$error }"
                      />

                      <div
                        v-if="$v.anggota.foto_diri.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.anggota.foto_diri.required"
                          >Foto diri harus di isi.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Ahli Waris</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Informasi tentang Ahli Waris
                  </h6>
                  <h5>Ahli Waris 1</h5>
                  <div class="row mt-3 px-2">
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Nama</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="anggota.ahli_waris_1_nama"
                      />
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">NIK</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="anggota.ahli_waris_1_nik"
                      />
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">No. HP</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="anggota.ahli_waris_1_no_hp"
                      />
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Hubungan Keluarga</label>
                      <select
                        class="form-select"
                        v-model="anggota.ahli_waris_1_hubungan"
                      >
                        <option value="">Pilih...</option>
                        <option
                          v-for="option in hubungan_keluarga"
                          :value="option"
                          :key="option"
                        >
                          {{ option }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <hr />
                  <h5>Ahli Waris 2</h5>
                  <div class="row mt-3 px-2">
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Nama</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="anggota.ahli_waris_2_nama"
                      />
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">NIK</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="anggota.ahli_waris_2_nik"
                      />
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">No. HP</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="anggota.ahli_waris_2_no_hp"
                      />
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">Hubungan Keluarga</label>
                      <select
                        class="form-select"
                        v-model="anggota.ahli_waris_2_hubungan"
                      >
                        <option value="">Pilih...</option>
                        <option
                          v-for="option in hubungan_keluarga"
                          :value="option"
                          :key="option"
                        >
                          {{ option }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <hr class="my-4" />

              <button
                class="w-100 btn btn-primary btn-lg"
                aria-hidden="true"
                :disabled="isLoading"
              >
                <div
                  class="spinner-border text-light"
                  role="status"
                  v-if="isLoading"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                {{
                  isLoading ? "Sedang mengirim data...mohon tunggu" : "Kirim"
                }}
              </button>

              <div
                class="alert alert-danger d-flex align-items-center"
                role="alert"
                v-if="isError"
              >
                <div>{{ errorMessage }}</div>
              </div>
            </form>
          </div>
        </div>
      </main>

      <!-- Error card notification -->
      <main
        v-else-if="!isVerifyingToken && !isTokenVerified && !registerSuccess"
        class="w-100"
        key="show_invalid_token"
      >
        <div class="d-flex justify-content-center mt-5">
          <div class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Gagal Memverifikasi Token</h4>
            <hr />
            <p class="mb-0">
              {{ verifyFailedMessage }}
            </p>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <p class="lead">{{ verifyFailedMessage.message }}</p>
        </div>
      </main>

      <!-- Success card notification -->
      <main
        v-else-if="registerSuccess"
        class="w-100"
        key="show_register_success"
      >
        <div class="d-flex justify-content-center mt-5">
          <img
            class="d-block mx-auto mb-4 mt-5"
            src="@/assets/logo.png"
            alt="Logo XTC Indonesia"
            width="128"
            height="128"
          />
        </div>

        <div class="d-flex justify-content-center mt-2">
          <h2>Registrasi Berhasil</h2>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <p class="lead">
            Registrasi berhasil. PAC di wilayah anda akan memverifikasi data
            secepatnya. Cek email secara berkala untuk mendapatkan informasi
            lebih lanjut.
          </p>
        </div>
      </main>

      <!-- Loading card notification -->
      <main v-else class="w-100" key="show_verifying">
        <div class="d-flex justify-content-center mt-5">
          <div class="spinner-border text-info" role="status"></div>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <p class="lead">Memverifikasi token...</p>
        </div>
      </main>
    </transition>

    <footer class="my-5 pt-5 text-muted text-center text-small">
      <p class="mb-1">&copy; 1982–2023 XTC Indonesia</p>
    </footer>
  </div>
</template>

<script>
import {
  provinsiService,
  kotaService,
  kecamatanService,
  kelurahanService,
  kategoriHobiService,
  kategoriPekerjaanService,
  hobiService,
  pekerjaanService,
} from "@/services";
import { required, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      locale: "id-ID",
      // Data form
      anggota: {
        // Informasi Umum
        nama_lengkap: "",
        nama_panggilan: "",
        jenis_kelamin: "",
        tanggal_lahir: "",
        email: "",
        no_hp: "",
        agama: "",
        status: "",
        jenis_identitas: "",
        no_identitas: "",

        // Domisili
        propinsi_id: "",
        kota_id: "",
        kecamatan_id: "",
        kelurahan_id: "",
        alamat: "",

        // Hobi & Pekerjaan
        kategori_hobi_id: "",
        hobi_id: "",
        kategori_pekerjaan_id: "",
        pekerjaan_id: "",

        // KTA
        foto_identitas: "",
        foto_memegang_identitas: "",
        foto_diri: "",
        jenis_atribut: "",
        ukuran_atribut: "",

        // Ahli Waris
        ahli_waris_1_nama: "",
        ahli_waris_1_nik: "",
        ahli_waris_1_no_hp: "",
        ahli_waris_1_hubungan: "",
        ahli_waris_2_nik: "",
        ahli_waris_2_nama: "",
        ahli_waris_2_no_hp: "",
        ahli_waris_2_hubungan: "",
      },

      // Data untuk select
      provinsi: [],
      kota: [],
      kecamatan: [],
      kelurahan: [],
      jenis_identitas: [
        "KTP",
        "SIM",
        "Kartu Pelajar",
        "Kartu Keluarga",
        "Paspor",
      ],
      jenis_atribut: [
        "Plus Kemeja (Rp.200.000)",
        "Plus Kaos (Rp.150.000)",
        "Hanya KTA (Rp.55.000)",
      ],
      ukuran_atribut: [
        "XS (46 X 66 CM)",
        "S (48 X 68 CM)",
        "M (50 X 70 CM)",
        "L (52 X 72 CM)",
        "XL (54 X 74 CM)",
        "XXL (56 X 76 CM)",
        "3XL (58 X 76 CM)",
      ],
      agama: ["Islam", "Kristen", "Hindu", "Buddha", "Khonghucu"],
      jenis_kelamin: ["Laki-Laki", "Perempuan"],
      status: ["Belum Menikah", "Sudah Menikah", "Janda", "Duda"],
      kategori_hobi: [],
      hobi: [],
      kategori_pekerjaan: [],
      pekerjaan: [],
      hubungan_keluarga: [
        "Kakek",
        "Nenek",
        "Ibu",
        "Ayah",
        "Paman",
        "Bibi",
        "Saudara/i",
        "Anak",
        "Mertua",
        "Mantu",
        "Ipar",
      ],

      // Form
      isLoading: false,
      isVerifyingToken: true,
      isTokenVerified: false,
      verifyFailedMessage: "",
      registerSuccess: false,
      isError: false,
      errorMessage: "",
    };
  },
  methods: {
    async verifyEmailToken() {
      try {
        const res = await this.axios.post("/register/verify_email_token", {
          token: this.$route.params.id,
        });
        this.isTokenVerified = true;
        this.anggota.email = res.data.email;
      } catch (error) {
        this.verifyFailedMessage =
          (error.response && error.response.data.message) ||
          "Kesalahan jaringan.";
        this.isTokenVerified = false;
      }

      this.isVerifyingToken = false;
    },
    async submitForm() {
      this.isError = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        var bodyFormData = new FormData();

        // Informasi Umum
        bodyFormData.append("nama_lengkap", this.anggota.nama_lengkap);
        bodyFormData.append("nama_panggilan", this.anggota.nama_panggilan);
        bodyFormData.append("jenis_kelamin", this.anggota.jenis_kelamin);
        bodyFormData.append("tanggal_lahir", this.anggota.tanggal_lahir);
        bodyFormData.append("email", this.anggota.email);
        bodyFormData.append("no_hp", this.anggota.no_hp);
        bodyFormData.append("agama", this.anggota.agama);
        bodyFormData.append("status", this.anggota.status);
        bodyFormData.append("jenis_identitas", this.anggota.jenis_identitas);
        bodyFormData.append("no_identitas", this.anggota.no_identitas);

        // Alamat & Domisili
        bodyFormData.append("propinsi_id", this.anggota.propinsi_id);
        bodyFormData.append("kota_id", this.anggota.kota_id);
        bodyFormData.append("kecamatan_id", this.anggota.kecamatan_id);
        bodyFormData.append("kelurahan_id", this.anggota.kelurahan_id);
        bodyFormData.append("alamat", this.anggota.alamat);

        // Hobi & Pekerjaan
        bodyFormData.append("kategori_hobi_id", this.anggota.kategori_hobi_id);
        bodyFormData.append("hobi_id", this.anggota.hobi_id);
        bodyFormData.append(
          "kategori_pekerjaan_id",
          this.anggota.kategori_pekerjaan_id
        );
        bodyFormData.append("pekerjaan_id", this.anggota.pekerjaan_id);

        // KTA
        bodyFormData.append("foto_identitas", this.anggota.foto_identitas);
        bodyFormData.append(
          "foto_memegang_identitas",
          this.anggota.foto_memegang_identitas
        );
        bodyFormData.append("foto_diri", this.anggota.foto_diri);
        bodyFormData.append("jenis_atribut", this.anggota.jenis_atribut);
        bodyFormData.append("ukuran_atribut", this.anggota.ukuran_atribut);

        // Ahli Waris
        bodyFormData.append(
          "ahli_waris_1_nama",
          this.anggota.ahli_waris_1_nama
        );
        bodyFormData.append("ahli_waris_1_nik", this.anggota.ahli_waris_1_nik);
        bodyFormData.append(
          "ahli_waris_1_no_hp",
          this.anggota.ahli_waris_1_no_hp
        );
        bodyFormData.append(
          "ahli_waris_1_hubungan",
          this.anggota.ahli_waris_1_hubungan
        );
        bodyFormData.append(
          "ahli_waris_2_nama",
          this.anggota.ahli_waris_2_nama
        );
        bodyFormData.append("ahli_waris_2_nik", this.anggota.ahli_waris_2_nik);
        bodyFormData.append(
          "ahli_waris_2_no_hp",
          this.anggota.ahli_waris_2_no_hp
        );
        bodyFormData.append(
          "ahli_waris_2_hubungan",
          this.anggota.ahli_waris_2_hubungan
        );

        // Token
        bodyFormData.append("token_id", this.$route.params.id);

        try {
          await this.axios({
            method: "post",
            url: "/anggota/daftar",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          });
          this.registerSuccess = true;
        } catch (error) {
          this.isError = true;
          this.errorMessage =
            (error.response && error.response.data.message) || error.message;
        }

        this.isLoading = false;
      }
    },
    changeFotoIdentitas(e) {
      const selectedFile = e.target.files[0];
      this.anggota.foto_identitas = selectedFile;
    },
    changeFotoMemegangIdentitas(e) {
      const selectedFile = e.target.files[0];
      this.anggota.foto_memegang_identitas = selectedFile;
    },
    changeFotoDiri(e) {
      const selectedFile = e.target.files[0];
      this.anggota.foto_diri = selectedFile;
    },
    onProvinceChange(propinsi_id) {
      kotaService.getAll("", "", "", "", "", propinsi_id).then((resp) => {
        this.kota = resp.rows;
      });
    },
    onKotaChange(kota_id) {
      kecamatanService.getAll("", "", "", "", "", kota_id).then((resp) => {
        this.kecamatan = resp.rows;
      });
    },
    onKecamatanChange(kecamatan_id) {
      kelurahanService.getAll("", "", "", "", "", kecamatan_id).then((resp) => {
        this.kelurahan = resp.rows;
      });
    },
    onKategoriPekerjaanChange(kategori_pekerjaan_id) {
      pekerjaanService
        .getAll("", "", "", "", "", kategori_pekerjaan_id)
        .then((resp) => {
          this.pekerjaan = resp.rows;
        });
    },
    onKategoriHobiChange(kategori_hobi_id) {
      hobiService.getAll("", "", "", "", "", kategori_hobi_id).then((resp) => {
        this.hobi = resp.rows;
      });
    },
    onJenisAtributChange() {
      this.anggota.ukuran_atribut = "";
    },
  },
  computed: {
    hanyaKta() {
      return this.anggota.jenis_atribut === "Hanya KTA (Rp.55.000)";
    },
  },
  mounted() {
    // Verify email token
    this.verifyEmailToken();

    // Get propinsi
    provinsiService.getAll(1000, "", 1).then((resp) => {
      this.provinsi = resp.rows;
    });

    // Get hobi & pekerjaan
    kategoriHobiService.getAll(1000, "", 1).then((resp) => {
      this.kategori_hobi = resp.rows;
      this.onKategoriHobiChange(this.anggota.kategori_hobi_id);
    });

    kategoriPekerjaanService.getAll(1000, "", 1).then((resp) => {
      this.kategori_pekerjaan = resp.rows;
      this.onKategoriPekerjaanChange(this.anggota.kategori_pekerjaan_id);
    });
  },
  validations: {
    anggota: {
      nama_lengkap: {
        required,
      },
      email: {
        required,
        email,
      },
      alamat: {
        required,
      },
      propinsi_id: {
        required,
      },
      kota_id: {
        required,
      },
      kecamatan_id: {
        required,
      },
      kelurahan_id: {
        required,
      },
      jenis_identitas: {
        required,
      },
      no_identitas: {
        required,
      },
      foto_identitas: {
        required,
      },
      foto_memegang_identitas: {
        required,
      },
      foto_diri: {
        required,
      },
      jenis_atribut: {
        required,
      },
      ukuran_atribut: {
        required: function () {
          return this.hanyaKta || this.anggota.ukuran_atribut.length > 0;
        },
      },
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 960px;
}
</style>
